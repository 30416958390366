import Display from "../sections/products/Display.js"

function Products() {
    return (
        <>
            <Display/>
        </>
    );
};

export default Products;