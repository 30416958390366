import Banner from "../sections/home/Banner.js"
import Highlights from "../sections/home/Highlights.js"

function Home() {
    return (
        <>
            <Banner/>
            <Highlights/>
        </>
    );
};

export default Home;